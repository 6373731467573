@import "~react-image-gallery/styles/css/image-gallery.css";

.header-image {
    pointer-events: none;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
    pointer-events: none;
}

.MuiCardMedia-img {
    pointer-events: none;
}

.breadcrumbs-button:hover {
    text-decoration-line: underline !important;
}

.css-1h4acdg-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black !important;
}